<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Site Group">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          <b-col md="12">

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Group Name"
                  class="required"
                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="form.name"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row >

              <b-col md="12">
                <b-form-group
                  label="Select Sites"
                  class="required"
                >

                  <v-select
                    v-model="form.user_sites"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="site_name"
                    :options="sites"
                    placeholder="Select Project Sites"
                    @option:deselected="logger"
                    @option:selected="addlogger"
                    :close-on-select=false
                    :clear-on-select=false
                    class="multiselect_muliple_options"
                  />
                   
                </b-form-group>
              </b-col>

            </b-row>

            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                    label=""
                    class=""
                  >
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="form.allow_site_selection"
                      value="yes"
                      unchecked-value="no"
                    >
                      Allow Site Selection
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          

            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'site-group' })"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import vSelect from 'vue-select'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    BBreadcrumb,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        user_sites : [],
        sites:[],
        allow_site_selection: 'no',
        name:'',
        id:''
      },
      sites:[],
      
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-site-group'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'site-group' })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    groupDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/site-group-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                this.form.sites = data.sites;
                
                var u_site = [];

                u_site = this.sites.map(item => { return item._id});

                
                this.form.user_sites = this.form.sites.filter(item => {
                  if (u_site.indexOf(item._id) >= 0) {
                    return item;
                  }
                })
                
                this.form.name = data.name;
                this.form.allow_site_selection = data.allow_site_selection;
              
                this.form.id = data._id;
                return this.form;
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Project Sites'
      },{
        to:{name:'site-group'},
        text: 'Site Group',
      },{
        to:null,
        text:'Edit Site Group'
      }];
      return item;
    },
    logger(e){

      var selectedSite = this.form.sites;
      
      var index = 0;

      for (var i = 0; i < selectedSite.length; i++) {
        if (selectedSite[i]._id == e._id) {

          index = i;
          break;
        }
      }

      selectedSite = selectedSite.splice(0,index);

      this.form.sites = selectedSite;

    },
    addlogger(e){
      this.form.sites.push(e[e.length -1]);
    }
    

  },
  mounted(){
    this.allSites().then(() => {
      this.groupDetail();
    });
  }
}
</script>
